<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$fun.routerToPage('/user/index')" />
    </template>
    <template #default>
      <span>
        {{ $fanyi("设置") }}
      </span>
    </template>
    <!--设置内容选项区域 内容区域 -->
  </headBackBar>

  <!-- 内容区域 -->
  <div class="Content">
    <!-- 客户设置 -->
    <div
      class="item useritem"
      @click="$fun.routerToPage('/user/personalinformation')"
    >
      <div class="left-text">
        <div class="img-box">
          <van-image
            lazy-load
            class="userPicture"
            :src="$store.state.userInfo.portrait_url"
          >
            <template v-slot:loading>
              <img
                :src="require('@/assets/homePage/defaultUserHead.png')"
                alt=""
              />
            </template>
          </van-image>
        </div>
        <div class="userinfo">
          <h2>{{ $store.state.userInfo.realname }}</h2>
          <p>{{ $store.state.userInfo.username }}</p>
        </div>
      </div>
      <van-icon name="arrow" />
    </div>

    <!-- 配送管理 -->
    <div class="item" @click="$fun.routerToPage('/user/deliverySite')">
      <div class="left-text">
        <img
          class="dizhiimg"
          src="../../../../assets/user-img/usersetting/address.png"
          alt=""
        />
        <span>{{ $fanyi("配送管理") }}</span>
      </div>
      <van-icon name="arrow" />
    </div>
    <!-- 账户设置 -->
    <div class="item" @click="$fun.routerToPage('/user/accountsetting')">
      <div class="left-text">
        <img
          class="shezhiimg"
          src="../../../../assets/user-img/usersetting/矢量智能对象.png"
          alt=""
        />
        <span>{{ $fanyi("账户设置") }}</span>
      </div>
      <van-icon name="arrow" />
    </div>
    <!-- 隐私政策 -->

    <div class="item" @click="$fun.routerToPage('/privacyPolicy')">
      <div class="left-text">
        <img
          class="yinsiimg"
          src="../../../../assets/user-img/usersetting/yinsi.png"
          alt=""
        />
        <span>{{ $fanyi("隐私政策") }}</span>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="item" @click="$fun.routerToPage('/couponRedemption')">
      <div class="left-text">
        <img
          class="couponimg"
          :src="require('@/assets/user-img/usersetting/coupon.png')"
          alt=""
        />
        <span>{{ $fanyi("优惠券兑换") }}</span>
      </div>
      <van-icon name="arrow" />
    </div>
    <!--  退出登录 -->
    <div class="Logout" @click="loginOut">{{ $fanyi("退出登录") }}</div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
console.log(proxy.$store.state);

const loginOut = () => {
  localStorage.removeItem("japan_user_token");
  proxy.$store.commit("userData", null);
  proxy.$store.commit("setcartCount", 0);
  proxy.$store.commit("outUser");
  location.href = "/";
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 20px;

  // .userPicture {
  //   width: 80px;
  //   height: 80px;
  //   border-radius: 50%;
  // }

  .item {
    display: flex;
    justify-content: space-between;
    width: 690px;
    height: 100px;
    padding-left: 33px;
    padding-right: 29px;
    align-items: center;
    background: #ffffff;
    font-size: 28px;

    font-weight: 400;
    color: #000000;
    border-radius: 6px;
    margin-bottom: 20px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dizhiimg {
        width: 29px;
        height: 36px;
      }

      .shezhiimg {
        width: 40px;
        height: 40px;
      }

      .kehuimg {
        width: 35px;
        height: 36px;
      }

      .yinsiimg {
        width: 40px;
        height: 40px;
      }
      .couponimg {
        width: 43px;
        height: 43px;
      }
      .banbenimg {
        width: 35px;
        height: 35px;
      }

      span {
        display: inline-block;
        margin-left: 31px;
        font-size: 28px;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .useritem {
    width: 690px;
    height: 150px;
    background: #ffffff;
    border-radius: 6px;

    .img-box {
      width: 80px;
      height: 80px;
      background: #ffdbd8;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 36px;
      height: 44px;
    }

    :deep().van-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    :deep() .van-image__img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    :deep().van-image__loading {
      // width: 80px;
      // height: 80px;
      // border-radius: 50%;
      background: transparent;
    }
  }

  .userinfo {
    margin-left: 21px;

    display: flex;
    flex-direction: column;

    h2 {
      width: 400px;
      font-size: 30px;

      font-weight: 600;
      color: #000000;
      margin-bottom: 17px;
      text-align: left;
    }

    p {
      width: 400px;
      font-size: 24px;

      font-weight: 400;
      color: #999999;
      text-align: left;
    }
  }

  .banben {
    display: flex;

    span {
      font-size: 28px;

      margin-right: 20px;
      font-weight: 400;
      color: #999999 !important;
    }
  }

  .Logout {
    width: 690px;
    height: 70px;
    background: #b4272b;
    margin-top: 40px;
    border-radius: 6px;
    font-size: 28px;

    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
